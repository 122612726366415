<template>
  <v-card style="position: relative" width="250" class="mx-auto flex d-flex flex-column">
    <v-card-text class="pb-16">
      <v-card height="150" v-if="item.image" flat>
        <v-img
          aspect-ratio="1.4"
          contain
          :src="`${item.aws}/courses/${item.uuid}/image/${item.image.image}`"
        />
      </v-card>
      <v-avatar v-else height="150" width="100%" tile color="success" />
      <div
        class="body-1 font-weight-bold mt-2"
        :class="user.dark == 1 ? 'white--text' : 'black--text'"
      >
        {{ item.title }}
      </div>
      <!-- <div
        v-if="!more"
        class="caption"
        v-html="item.description"
        style="height: 20px; overflow: hidden"
      />
      <div v-else class="caption" v-html="item.description" />
      <div
        style="cursor: pointer"
        @click="more = !more"
        class="info--text pt-5"
        v-if="item.description"
      >
        Read{{ more ? " short" : " more..." }}
      </div> -->
      
    </v-card-text>
    <!-- <v-divider/> -->
  
    <v-card-actions style="position: absolute; bottom: 0;">
      <div class="flex d-flex flex-column">
        <div class=" mb-2">
          <v-progress-linear
            :color="item.progress < 50 ? '#999' : 'success'"
            rounded
            height="20"
            :value="item.progress"
            stream
          >
            {{ Math.ceil(item.progress) }}%
          </v-progress-linear>
        </div>
        <div class="flex d-flex justify-space-around">
          <v-btn small :color="$helpers.progressColor(item.action)" @click="material(item)">
            {{
              item.action == "start"
                ? "Start Learning"
                : item.action == "continue"
                ? "Continue"
                : "Completed"
            }}
            <v-icon :left="item.action == 'completed' || item.action == 'continue'">
              {{
                item.action == "completed"
                  ? "mdi-check"
                  : item.action == "continue"
                  ? "mdi-chevron-right"
                  : ""
              }}
            </v-icon>
          </v-btn>
          <v-btn
            small
            text
            :to="{ name: 'Student Course Details', params: { uuid: item.uuid } }"
          >
            See Details
          </v-btn>
        </div>
        
      </div>
      
    </v-card-actions>
    <!-- <div class="d-flex align-end">
      <div class="">
        asdadsasd
      </div>
    </div> -->
  </v-card>
</template>

<script>
export default {
  props: ["item", "user"],
  data: () => ({
    more: false,
  }),
  methods: {
    material(item) {
      if (item.start == null) return alert("No Lesson found!");

      if (item.lessons_count == 0) return alert("No Lesson found!");

      if (item.start.status == 1) {
        if (item.start.type == "Assessment") {
          this.$router.push({
            name: "Student Assessment",
            params: { uuid: item.start.assessment.uuid, type: "lesson" },
          });
          return;
        }
        this.$router.push({
          name: "Student Material",
          params: { uuid: item.start.uuid },
        });
        return;
      }

      alert("Next topic is not yet open");
    },
  },
};
</script>
